import { NavLink, useLocation } from "react-router-dom";
import "./Menu.css";
import { useState } from "react";
import SideBar from "./sidebar";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Menu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showJobMenu, setShowJobMenu] = useState(false);

  const handleHover = () => {
    setShowSubMenu(true);
  };

  const handleLeave = () => {
    setShowSubMenu(false);
  };

  const handleJobHover = () => {
    setShowJobMenu(true);
  };

  const handleJobLeave = () => {
    setShowJobMenu(false);
  };

  const location = useLocation();
  const shouldShowUserIcon =
    location.pathname === "/docs" ||
    location.pathname === "/alerts" ||
    location.pathname === "/macro" ||
    location.pathname === "/indices-view" ||
    location.pathname === "/news" ||
    location.pathname === "/upwork" ||
    location.pathname === "/installs-downloads" ||
    location.pathname === "/company" ||
    location.pathname === "/market-analysis";

  const user = window.localStorage.getItem("revelera_user");
  const userParse = JSON.parse(user);
  const userPicture = userParse && userParse.picture ? userParse.picture : "";
  const userIcon = userPicture ? (
    <div className="userIcon">
      <img src={userPicture} style={{ width: "100%", borderRadius: "25px" }} />
    </div>
  ) : (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="button-tooltip-2">
          {userParse ? userParse.email : "Avatar is'nt available"}
        </Tooltip>
      }
    >
      <div className="userIcon without-image">
        {userParse ? userParse.email.substring(0, 2).toUpperCase() : ""}
      </div>
    </OverlayTrigger>
  );
  return (
    <nav className={`MenuLinks-Container ${isMenuOpen ? "menu-open" : ""}`}>
      <div id="App">
        <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />
      </div>
      <ul
        style={{
          listStyle: "none",
          margin: "auto",
          padding: "0",
        }}
      >
        <li
          key="job-trends"
          style={{
            margin: "0px",
            display: "inline-block",
          }}
          onMouseEnter={handleJobHover}
          onMouseLeave={handleJobLeave}
        >
          <NavLink to="/jobs">
            Job Trends <i className="bi bi-caret-down-fill"></i>
          </NavLink>
          {showJobMenu && (
            <ul
              style={{
                listStyle: "none",
                padding: "5px 0px",
                position: "absolute",
                top: "1",
                // left: "0",
                backgroundColor: "#343a40",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                zIndex: 1,
                minWidth: "150px",
                borderRadius: "4px",
                border: "1px solid #ddd",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <li style={{ margin: "5px 0px" }}>
                <NavLink to="/jobs">Job Trends</NavLink>
              </li>
              <li style={{ margin: "5px 0px" }}>
                <NavLink to="/company">Company Trends</NavLink>
              </li>
              {/* <li style={{ margin: "5px 0px" }}>
                <NavLink to="/market-analysis">Market Analysis</NavLink>
              </li> */}
            </ul>
          )}
        </li>
        <li
          key="product-mentions"
          style={{
            margin: "0px",
            display: "inline-block",
          }}
        >
          <NavLink to="/skills">Product Mentions</NavLink>
        </li>
        <li
          key="saas-subscriptions"
          style={{
            margin: "0px",
            display: "inline-block",
          }}
        >
          <NavLink to="/saas">SaaS</NavLink>
        </li>
        <li
          key="macro"
          style={{
            margin: "0px",
            display: "inline-block",
          }}
        >
          <NavLink to="/macro">macro</NavLink>
        </li>
        <li
          key="indices-views"
          style={{
            margin: "0px",
            display: "inline-block",
          }}
        >
          <NavLink to="/indices-view">Indices</NavLink>
        </li>
        <li
          key="upwork"
          style={{
            margin: "0px",
            display: "inline-block",
            position: "relative",
          }}
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
        >
          <NavLink
            to="#"
            className={
              location.pathname !== "/upwork" &&
              location.pathname !== "/installs-downloads"
                ? "border-bottom-0"
                : ""
            }
          >
            Trends <i className="bi bi-caret-down-fill"></i>
          </NavLink>
          {showSubMenu && (
            <ul
              style={{
                listStyle: "none",
                padding: "5px 0px",
                position: "absolute",
                top: "100%",
                left: "0",
                backgroundColor: "#343a40",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                zIndex: 1,
                minWidth: "150px",
                borderRadius: "4px",
                border: "1px solid #ddd",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <li style={{ margin: "5px 0px" }}>
                <NavLink to="/upwork">Upwork</NavLink>
              </li>
              <li style={{ margin: "5px 0px" }}>
                <NavLink to="/installs-downloads">Installs/Downloads</NavLink>
              </li>
            </ul>
          )}
        </li>
        <li
          key="alerts"
          style={{
            margin: "0px",
            display: "inline-block",
          }}
        >
          <NavLink to="/alerts">Alerts</NavLink>
        </li>
        <li
          key="news"
          style={{
            margin: "0px",
            display: "inline-block",
          }}
        >
          <NavLink to="/news">News</NavLink>
        </li>
        <li
          key="docs"
          style={{
            margin: "0px",
            display: "inline-block",
          }}
        >
          <NavLink to="/docs">Docs</NavLink>
        </li>
        {shouldShowUserIcon && <li className="icon">{userIcon}</li>}
      </ul>
    </nav>
  );
}

export default Menu;
