import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Dropdown } from "react-bootstrap";
import { Col, Card } from "react-bootstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import "./Company.css";
import CompanyCharts from "./CompanyChart";
import {
  getCompanyJobStats,
  getCompaniesName,
  getCompanyNewJobs,
  getCompaniesData,
} from "../../services/companyService";
import { css } from "@emotion/react";
import BounceLoader from "react-spinners/BounceLoader";
import CompanyComparisonCharts from "./CompanyComparisonCharts";
import { Form } from "react-bootstrap";

const override = css`
  display: block;
  margin: 0 auto;
`;

function Company() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [selectedSingleCompanyOption, setSelectedSingleCompanyOption] =
    useState("monthly-data");
  const [selectedMultipleCompanyOption, setSelectedMultipleCompanyOption] =
    useState("monthly-data");

  const onSelectPeriodTypeForSingleCompany = (val) => {
    setSelectedSingleCompanyOption(val);
    if (val === "quarterly-data") {
      setValue("periodOneQuarter", "Q1");
      setValue("periodTwoQuarter", "Q1");
    } else {
      setValue("periodOneQuarter", "January");
      setValue("periodTwoQuarter", "January");
    }
  };

  const onSelectPeriodTypeForMultipleCompany = (val) => {
    setSelectedMultipleCompanyOption(val);
    if (val === "quarterly-data") {
      setValue("periodMultipleQuarter", "Q1");
    } else {
      setValue("periodMultipleQuarter", "January");
    }
    fetchCompaniesData();
  };

  const { register, watch, setValue, getValues } = useForm({
    defaultValues: {
      companyName: "",
      periodOneQuarter: "January",
      periodTwoQuarter: "January",
      periodOneYear: null,
      periodTwoYear: null,
      companiesName: [],
      periodMultipleQuarter: "January",
      periodMultipleYear: null,
    },
  });

  const [companyList, setCompanyList] = useState([]);
  const [visibleCompanies, setVisibleCompanies] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState();
  const [newJobData, setNewJobData] = useState();
  const [companiesData, setCompaniesData] = useState();
  const [loading, setLoading] = useState(false);
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedTab, setSelectedTab] = useState("company-wise");

  const companyName = watch("companyName");
  const periodOneQuarter = watch("periodOneQuarter");
  const periodTwoQuarter = watch("periodTwoQuarter");
  const periodOneYear = watch("periodOneYear");
  const periodTwoYear = watch("periodTwoYear");
  const companiesName = watch("companiesName");
  const periodMultipleQuarter = watch("periodMultipleQuarter");
  const periodMultipleYear = watch("periodMultipleYear");
  const [hasFetchedCompaniesData, setHasFetchedCompaniesData] = useState(false);

  const handleSelectChange = (e) => {
    setValue("companyName", e?.value);
    fetchCompanyData();
    fetchNewJobCompanyData();
  };

  const handleSelectChange2 = (selectedOptions) => {
    // fetchCompanyData();

    if (selectedOptions.length <= 6) {
      const selectedCompanies = selectedOptions?.map((option) => option.value);
      setValue("companiesName", selectedCompanies);
      fetchCompaniesData();
    } else {
      toast("You can only select up to 6 companies.");
    }
  };

  const handleInputChange = (input) => {
    setInputValue(input);

    if (input === "") {
      setVisibleCompanies(companyList.slice(0, 100));
    } else {
      const filteredCompanies = companyList?.filter((company) => {
        return company?.toLowerCase().includes(input?.toLowerCase());
      });

      setVisibleCompanies(filteredCompanies);
    }
  };

  const onSelectQuarter1 = (e) => {
    setValue("periodOneQuarter", e);
    fetchCompanyData();
    fetchNewJobCompanyData();
  };

  const onSelectQuarter2 = (e) => {
    setValue("periodTwoQuarter", e);
    fetchCompanyData();
  };

  const onSelectPeriodOne = (e) => {
    setValue("periodOneYear", e);
    fetchCompanyData();
    fetchNewJobCompanyData();
  };

  const onSelectPeriodTwo = (e) => {
    setValue("periodTwoYear", e);
    fetchCompanyData();
  };

  const onSelectPeriodMultipleQuarter = (e) => {
    setValue("periodMultipleQuarter", e);
    fetchCompaniesData();
  };

  const onSelectPeriodMultipleYear = (e) => {
    setValue("periodMultipleYear", e);
    fetchCompaniesData();
  };

  const calculateYears = (startDate) => {
    const years = [];
    const currentYear = new Date().getFullYear();
    const startYear = startDate.getFullYear();

    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }

    return years;
  };

  const fetchCompanyNames = () => {
    setLoading(true);
    getCompaniesName()
      .then((res) => {
        const companies = res?.data?.companies;
        setCompanyList(companies);
        setValue("companyName", companies[0]);
        setValue("companiesName", companies.slice(0, 6));
        setVisibleCompanies(companies.slice(0, 100));

        fetchCompanyData(companies[0]);
        fetchNewJobCompanyData(companies[0]);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchCompanyData = (initialCompanyName) => {
    setLoading(true);
    const companyToFetch = initialCompanyName || getValues("companyName");
    getCompanyJobStats({
      companyName: companyToFetch,
      periodOneQuarter: getValues("periodOneQuarter"),
      periodOneYear: getValues("periodOneYear"),
      periodTwoQuarter: getValues("periodTwoQuarter"),
      periodTwoYear: getValues("periodTwoYear"),
    })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchCompaniesData = (initialCompaniesName) => {
    setLoading(true);
    const companyToFetch = initialCompaniesName || getValues("companiesName");
    const companyDataParams = {
      period: getValues("periodMultipleQuarter"),
      year: getValues("periodMultipleYear"),
    };

    companyToFetch.forEach((company, index) => {
      companyDataParams[`company_${index + 1}`] = company;
    });
    getCompaniesData(companyDataParams)
      .then((res) => {
        setCompaniesData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchNewJobCompanyData = (initialCompanyName) => {
    setLoading(true);
    const companyToFetch = initialCompanyName || getValues("companyName");
    getCompanyNewJobs({
      companyName: companyToFetch,
      periodOneQuarter: getValues("periodOneQuarter"),
      periodOneYear: getValues("periodOneYear"),
    })
      .then((res) => {
        setNewJobData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    fetchCompanyNames();

    const startDate = new Date("2023-02-01");
    const years = calculateYears(startDate);
    setValue("periodMultipleYear", years[0]);
    setValue("periodOneYear", years[0]);
    setValue("periodTwoYear", years[1]);
    setYearOptions(years);
  }, [selectedSingleCompanyOption]);

  useEffect(() => {
    if (!hasFetchedCompaniesData && selectedTab === "companies-wise") {
      fetchCompaniesData(companyList.slice(0, 6));
      setHasFetchedCompaniesData(true);
    }
  }, [selectedTab, selectedMultipleCompanyOption]);

  return (
    <>
      <div className="h-100">
        <nav>
          <ul
            style={{
              listStyle: "none",
              margin: "auto",
              padding: "0",
            }}
          >
            <li
              key="company-wise"
              className={`company-wise ${
                selectedTab === "company-wise" ? "active" : ""
              }`}
              onClick={() => setSelectedTab("company-wise")}
            >
              INDIVIDUAL
            </li>
            <li
              key="companies-wise"
              className={`company-wise ${
                selectedTab === "companies-wise" ? "active" : ""
              }`}
              onClick={() => setSelectedTab("companies-wise")}
            >
              MULTIPLE
            </li>
          </ul>
        </nav>
        {selectedTab === "company-wise" ? (
          <Col lg={12}>
            <Card className="p-0 pb-2">
              <Card.Title>
                <div className="skill-data-header-wrapper">
                  <div className="company-dropdown mt-4">
                    <div className="company-title company-name">
                      Company Name
                    </div>
                    <Select
                      className="text-muted xs-small m-2"
                      size="sm"
                      options={visibleCompanies?.map((e) => ({
                        value: e,
                        label: e,
                        key: e,
                      }))}
                      value={
                        companyName
                          ? { value: companyName, label: companyName }
                          : null
                      }
                      onChange={handleSelectChange}
                      onInputChange={handleInputChange}
                      inputValue={inputValue}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          minWidth: "200px",
                          backgroundColor: "#32323e",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          color: "#77778e",
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: "#77778e",
                        }),
                        option: (provided) => ({
                          ...provided,
                          backgroundColor: "#32323e",
                        }),
                      }}
                    />
                  </div>
                  <div className="header-right period-containor flex-wrap">
                    <div>
                      <div className="pb-2">
                        <Form
                          className="text-muted xs-small d-flex text-center"
                          id="dataTypeSelect"
                        >
                          <Form.Check
                            type="radio"
                            id="monthlyDataOption"
                            label="Monthly"
                            value="monthly-data"
                            checked={
                              selectedSingleCompanyOption === "monthly-data"
                            }
                            onChange={() =>
                              onSelectPeriodTypeForSingleCompany("monthly-data")
                            }
                          />
                          <Form.Check
                            type="radio"
                            id="quarterlyDataOption"
                            label="Quarterly"
                            value="quarterly-data"
                            checked={
                              selectedSingleCompanyOption === "quarterly-data"
                            }
                            onChange={() =>
                              onSelectPeriodTypeForSingleCompany(
                                "quarterly-data"
                              )
                            }
                            style={{ marginLeft: "9px" }}
                          />
                        </Form>
                      </div>
                      <div className="d-flex gap-2 flex-wrap">
                        <div className="period-1-containor">
                          <div>
                            <span className="company-title">Period 1</span>
                          </div>
                          <Dropdown
                            className="dropdown-select"
                            onSelect={onSelectQuarter1}
                          >
                            <Dropdown.Toggle className="dropdown-select">
                              {periodOneQuarter}
                            </Dropdown.Toggle>
                            {selectedSingleCompanyOption ===
                            "quarterly-data" ? (
                              <Dropdown.Menu>
                                <Dropdown.Item eventKey="Q1">Q1</Dropdown.Item>
                                <Dropdown.Item eventKey="Q2">Q2</Dropdown.Item>
                                <Dropdown.Item eventKey="Q3">Q3</Dropdown.Item>
                                <Dropdown.Item eventKey="Q4">Q4</Dropdown.Item>
                              </Dropdown.Menu>
                            ) : (
                              <Dropdown.Menu>
                                {months.map((month, index) => (
                                  <Dropdown.Item key={index} eventKey={month}>
                                    {month}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            )}
                          </Dropdown>
                          <Dropdown
                            onSelect={onSelectPeriodOne}
                            className="dropdown-select"
                          >
                            <Dropdown.Toggle className="dropdown-select">
                              <span>{periodOneYear}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {yearOptions?.map((year) => (
                                <Dropdown.Item key={year} eventKey={year}>
                                  {year}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="period-2-containor">
                          <div>
                            <span className="company-title">Period 2</span>
                          </div>
                          <Dropdown
                            className="dropdown-select"
                            onSelect={onSelectQuarter2}
                          >
                            <Dropdown.Toggle className="dropdown-select">
                              {periodTwoQuarter}
                            </Dropdown.Toggle>

                            {selectedSingleCompanyOption ===
                            "quarterly-data" ? (
                              <Dropdown.Menu>
                                <Dropdown.Item eventKey="Q1">Q1</Dropdown.Item>
                                <Dropdown.Item eventKey="Q2">Q2</Dropdown.Item>
                                <Dropdown.Item eventKey="Q3">Q3</Dropdown.Item>
                                <Dropdown.Item eventKey="Q4">Q4</Dropdown.Item>
                              </Dropdown.Menu>
                            ) : (
                              <Dropdown.Menu>
                                {months.map((month, index) => (
                                  <Dropdown.Item key={index} eventKey={month}>
                                    {month}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            )}
                          </Dropdown>
                          <Dropdown
                            onSelect={onSelectPeriodTwo}
                            className="dropdown-select"
                          >
                            <Dropdown.Toggle className="dropdown-select">
                              <span>{periodTwoYear}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {yearOptions?.map((year) => (
                                <Dropdown.Item key={year} eventKey={year}>
                                  {year}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Title>
            </Card>
            {loading ? (
              <Card>
                <BounceLoader color={"#36D7B7"} css={override} size={50} />
              </Card>
            ) : (
              <CompanyCharts
                data={data}
                newJobData={newJobData}
                periodOneQuarter={periodOneQuarter}
                periodOneYear={periodOneYear}
              />
            )}
          </Col>
        ) : (
          <Col lg={12}>
            <Card className="p-0 pb-2">
              <Card.Title>
                <div className="skill-data-header-wrapper d-flex gap-5">
                  <div className="company-dropdown mt-4">
                    <div className="company-title company-name">
                      Company Name
                    </div>
                    <Select
                      className="text-muted xs-small m-2"
                      size="sm"
                      options={visibleCompanies?.map((e) => ({
                        value: e,
                        label: e,
                        key: e,
                      }))}
                      isMulti
                      value={
                        companiesName.length > 0
                          ? companiesName?.map((company) => ({
                              value: company,
                              label: company,
                            }))
                          : null
                      }
                      onChange={handleSelectChange2}
                      onInputChange={handleInputChange}
                      inputValue={inputValue}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          minWidth: "200px",
                          backgroundColor: "#32323e",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          color: "#77778e",
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: "#77778e",
                        }),
                        option: (provided) => ({
                          ...provided,
                          backgroundColor: "#32323e",
                        }),
                      }}
                    />
                  </div>
                  <div className="header-right period-containor d-flex align-items-center">
                    <div>
                      <div className="pb-2">
                        <Form
                          className="text-muted xs-small d-flex"
                          id="multipleCompanyDataTypeSelect"
                        >
                          <Form.Check
                            type="radio"
                            id="monthlyDataOptionMultiple"
                            label="Monthly"
                            value="monthly-data"
                            checked={
                              selectedMultipleCompanyOption === "monthly-data"
                            }
                            onChange={() =>
                              onSelectPeriodTypeForMultipleCompany(
                                "monthly-data"
                              )
                            }
                          />
                          <Form.Check
                            type="radio"
                            id="quarterlyDataOptionMultiple"
                            label="Quarterly"
                            value="quarterly-data"
                            checked={
                              selectedMultipleCompanyOption === "quarterly-data"
                            }
                            onChange={() =>
                              onSelectPeriodTypeForMultipleCompany(
                                "quarterly-data"
                              )
                            }
                            style={{ marginLeft: "9px" }}
                          />
                        </Form>
                      </div>
                      <div className="period-1-containor">
                        <div>
                          <span className="company-title">Period </span>
                        </div>
                        <Dropdown
                          className="dropdown-select"
                          onSelect={onSelectPeriodMultipleQuarter}
                        >
                          <Dropdown.Toggle className="dropdown-select">
                            {periodMultipleQuarter}
                          </Dropdown.Toggle>

                          {selectedMultipleCompanyOption ===
                          "quarterly-data" ? (
                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="Q1">Q1</Dropdown.Item>
                              <Dropdown.Item eventKey="Q2">Q2</Dropdown.Item>
                              <Dropdown.Item eventKey="Q3">Q3</Dropdown.Item>
                              <Dropdown.Item eventKey="Q4">Q4</Dropdown.Item>
                            </Dropdown.Menu>
                          ) : (
                            <Dropdown.Menu>
                              {months.map((month, index) => (
                                <Dropdown.Item key={index} eventKey={month}>
                                  {month}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                        <Dropdown
                          onSelect={onSelectPeriodMultipleYear}
                          className="dropdown-select"
                        >
                          <Dropdown.Toggle className="dropdown-select">
                            <span>{periodMultipleYear}</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {yearOptions?.map((year) => (
                              <Dropdown.Item key={year} eventKey={year}>
                                {year}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Title>
            </Card>
            {loading ? (
              <Card>
                <BounceLoader color={"#36D7B7"} css={override} size={50} />
              </Card>
            ) : (
              <CompanyComparisonCharts data={companiesData} />
            )}
          </Col>
        )}
      </div>
    </>
  );
}

export default Company;
