export const verticalBarChartData = {
    legend: {
        show: false,
        textStyle: {
            color: "#ffffff",
        },
    },
    tooltip: {},
    dataset: {
        source: [],
    },
    xAxis: {
        type: "category",
        axisLabel: {
            color: "#ffffff",
        },
    },
    yAxis: {
        axisLabel: {
            color: "#ffffff",
        },
    },
    series: [],
};

export const pieChartData =  {  
    backgroundColor: "#32323e",
    title: {
        text: "Period 1",
        left: "center",
        textStyle: {
            color: "#fff",
            fontSize: 14,
        },
    },
    tooltip: {
        trigger: "item",
        backgroundColor: "#444",
        textStyle: {
            color: "#fff",
            fontSize: 10,
        },
    },
    legend: {
        show: true,
        orient: "horizontal",
        type: 'scroll',
        left: "left",
        top: 20,
        textStyle: {
            color: "#fff",
            fontSize: 10,
        },
    },
    series: [
        {
            name: "Jobs",
            type: "pie",
            radius: "70%",
            top: "10%",
            data: [],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                },
            },
            label: {
                color: "#fff",
                fontSize: 10,
            },
        },
    ],
};

export const pieChartData2 =  {
  backgroundColor: "#32323e",
  title: {
      text: "Period 1",
      left: "center",
      textStyle: {
          color: "#fff",
          fontSize: 14,
      },
  },
  tooltip: {
      trigger: "item",
      backgroundColor: "#444",
      textStyle: {
          color: "#fff",
          fontSize: 10,
      },
  },
  legend: {
      show: false,
      orient: "horizontal",
      type: 'scroll',
      left: "left",
      top: 20,
      textStyle: {
          color: "#fff",
          fontSize: 10,
      },
  },
  series: [
      {
          name: "Jobs",
          type: "pie",
          radius: "70%",
          top: "7%",
          bottom: 50,
          data: [],
          emphasis: {
              itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
              },
          },
          label: {
              color: "#fff",
              fontSize: 10,
          },
      },
  ],
};

export const lineChartOptions = {
    backgroundColor: "#32323e",
    title: {
      text: "Job Count Over Time",
      left: "center",
      textStyle: {
        color: "#fff",
      },
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "#444",
      textStyle: {
        color: "#fff",
      },
    },
    legend: {
      data: ["Jobs"],
      bottom: 0,
      textStyle: {
        color: "#fff",
      },
    },
    xAxis: {
      type: "category",
      data: [],
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        color: "#77778e",
        rotate: 28,
        fontSize: 11,
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        color: "#fff",
      },
      splitLine: {
        lineStyle: {
          color: "#555",
        },
      },
    },
    series: [
      {
        name: "Jobs",
        data: [],
        type: "line",
        smooth: true,
        itemStyle: {
          color: "#3498db",
        },
        lineStyle: {
          color: "#3498db",
        },
        areaStyle: {
          color: "rgba(52, 152, 219, 0.2)",
        },
      },
    ],
};
