import axios from "axios";

export const getJobTitles = () => {
    const accessToken = window.localStorage.getItem("accessToken");
    return axios({
        method: "get",
        url: process.env.REACT_APP_SERVER_API + "api/job-title-list",
        headers: {
            Authorization: `Token ${accessToken}`,
            "Content-Type": "application/json",
        },
        responseType: "json",
    });
};

export const getJobLocations = (props) => {
    const accessToken = window.localStorage.getItem("accessToken");
    return axios({
        method: "get",
        url: process.env.REACT_APP_SERVER_API + "api/job-location-list",
        headers: {
            Authorization: `Token ${accessToken}`,
            "Content-Type": "application/json",
        },
        responseType: "json",
    });
};

export const getJobCountAndSalary = (props) => {
    const accessToken = window.localStorage.getItem("accessToken");
    return axios({
        method: "get",
        url: process.env.REACT_APP_SERVER_API + "api/job-title-data",
        headers: {
            Authorization: `Token ${accessToken}`,
            "Content-Type": "application/json",
        },
        params: {
            job_title: props.job_title,
        },
        responseType: "json",
    });
};

export const getJobCountAndSalaryByLocation = (props) => {
    const accessToken = window.localStorage.getItem("accessToken");
    return axios({
        method: "get",
        url: process.env.REACT_APP_SERVER_API + "api/jobcount",
        headers: {
            Authorization: `Token ${accessToken}`,
            "Content-Type": "application/json",
        },
        params: {
            job_title: props.job_title,
            job_location: props.job_location,
        },
        responseType: "json",
    });
};
