import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Row, Col, Card } from "react-bootstrap";
import { lineChartOptions } from "../../utils/Constants";

function MarketAnalysisCharts(props) {
  const {jobStats, salaryStats} = props;
  
  const [jobsData, setJobsData] = useState(lineChartOptions);
  const [salaryData, setSalaryData] = useState(lineChartOptions);

  const transformatedJobsData = () => {
    setJobsData((prevData) => ({
      ...prevData,
      title: {
        text: "Job Count Over Time",
      },
      xAxis: {
        data: jobStats?.map((item) => item?.date),
      },
      series: [
        {
          data: jobStats?.map((item) => item?.job_count),
        },
      ],
    }));
  };

  const transformatedSalaryData = () => {
    setSalaryData((prevData) => ({
      ...prevData,
      title: {
        text: "Salary Ranges Over Time",
      },
      legend: {
        data: ["Min Salary", "Max Salary"],
        bottom: 0,
        textStyle: {
          color: "#fff",
        },
      },
      xAxis: {
        data: salaryStats?.map((item) => item?.month),
      },
      series: [
        {
          name: "Min Salary",
          type: "line",
          data: salaryStats?.map((item) => item?.min_salary),
          itemStyle: {
            color: "#2ecc71",
          },
          lineStyle: {
            color: "#2ecc71",
          },
          areaStyle: {
            color: "rgba(46, 204, 113, 0.2)",
          },
        },
        {
          name: "Max Salary",
          type: "line",
          data: salaryStats?.map((item) => item?.max_salary),
          itemStyle: {
            color: "#e74c3c",
          },
          lineStyle: {
            color: "#e74c3c",
          },
          areaStyle: {
            color: "rgba(231, 76, 60, 0.2)",
          },
        },
      ],
    }));
  };

  useEffect(() => {
    transformatedJobsData();
    transformatedSalaryData();
  }, [props]);

  return (
    <>
      <Card>
        <Row>
          <Col md={6} className="mb-4">
            <ReactECharts option={jobsData} style={{ height: "300px" }} />
          </Col>
          <Col md={6} className="mb-4">
            <ReactECharts option={salaryData} style={{ height: "300px" }} />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default MarketAnalysisCharts;
