import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Card, Button } from "react-bootstrap";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import { Form } from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./HistoricTable.css";
import BounceLoader from "react-spinners/BounceLoader";
import {
  countryDataList,
  historicIndustryData,
  marcosDataList,
  sourceCountryDataList,
} from "../../services/industryService";
import IndustryChart from "../Industry/IndustryChart";
import { useDispatch } from "react-redux";
import { createCsv } from "./../../services/historicDataService";
import { reloadIfTokenExpire } from "../../services/common";
const override = css`
  display: block;
  margin: 0 auto;
`;

function IndustryTable() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [industryOrSectorList, setIndustryOrSectorList] = useState([]);
  const [isChecked, setIsChecked] = useState("source");
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [industryName, setIndustryName] = useState(industryOrSectorList[0]);
  const handleSwitchChange = (index) => {
    if(index == "sector"){
      setIsChecked("sector");
    }
    if(index == "industry"){
      setIsChecked("industry");
    }
    if(index == "source"){
      setIsChecked("source");
    }
  };

  useEffect(() => {
    if (!isFirstLoad) {
      if(isChecked == "sector"){
        setIndustryOrSectorList(sectorList);
        getIndustryData(sectorList[0]);
      }
      if(isChecked == "industry"){
        setIndustryOrSectorList(industryList);
        getIndustryData(industryList[0]);
      }
      if(isChecked == "source"){
        setIndustryOrSectorList(sourceList);
        fetchSourceCountryData(sourceList[0]);
      }
    } else {
      setIsFirstLoad(false);
    }
  }, [isChecked]);

  let columns = [
    {
      dataField: "change_date",
      text: "Snapshot Date",
      sort: true,
      headerStyle: (col, colIdx) => {
        return { width: "200px" };
      },
    },
    { dataField: "total_job_openings", text: `${isChecked == "source" ? "num new jobs" : "Total job openings"}`, sort: true },
    { dataField: "new_job_openings", text: `${isChecked == "source" ? "num new jobs past x days" : "New job openings"}`, sort: true },
  ];

  const getIndustryData = (industry) => {
    setIndustryName(industry);
    setLoading(true);  
    historicIndustryData(industry,isChecked)
      .then((res) => {
        reloadIfTokenExpire(res.data);
        if (res.data !== "No Response") {
          const data = res.data;
          const industryDataObj = [];
          const data_keys = Object.keys(data);
          data_keys.map((date, index) => {
            const row = data[date];
            industryDataObj.push({
              key: index,
              change_date: date,
              total_job_openings: row["total_job_openings"],
              new_job_openings: row["new_job_openings"],
            });
          });
          dispatch({
            type: "INDUSTRY_TABLE_DATA",
            industryTableData: industryDataObj,
          });
          setFinalData(industryDataObj);
          setLoading(false);
        } else {
          console.log("Industry/Sector Not Found");
        }
      })
      .catch((err) => {
        reloadIfTokenExpire(err.response.data);
        console.log("Industry/Sector Not Found");
      });
  };

  const fetchSourceCountryData = (country) =>{
    setIndustryName(country);
    setLoading(true);
    sourceCountryDataList(country).then((res)=>{
      if (res.data !== "No Response") {
        const data = res.data;
        const sourceDataObj = [];
        const data_keys = Object.keys(data);
        data_keys.map((date, index) => {
          const row = data[date];
          sourceDataObj.push({
            key: index,
            change_date: date,
            total_job_openings: row["num_new_jobs"],
            new_job_openings: row["num_new_jobs_past_x_days"],
          });
        });
        setFinalData(sourceDataObj);
        setLoading(false);
      }else {
        console.log("Country/Source Not Found");
      }
    })
    .catch((err) => {
      console.log("Country/Source Not Found");
    });
  }

  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) {
      window.location.href = '/'
    }
    let isMounted = true; // Flag to track component's mount state

    
    const fetchMacrosData = () => {

      countryDataList()
      .then((data) => {
        reloadIfTokenExpire(data.data);
        if (isMounted) {
          // Check if component is still mounted before updating state
          console.log('data.data', data.data.countrylist);
          setSourceList(data.data.countrylist);
          setIndustryOrSectorList(data.data.countrylist);     
        }
      })
      .catch((error) => {
        reloadIfTokenExpire(error.response.data);
        console.error("Error fetching industry data:", error);
      });

      marcosDataList()
        .then((data) => {
          reloadIfTokenExpire(data.data);
          if (isMounted) {
            // Check if component is still mounted before updating state
            setIndustryList(data.data.industryList);
            setSectorList(data.data.sectorList);
          }
        })
        .catch((error) => {
          reloadIfTokenExpire(error.response.data);
          console.error("Error fetching industry data:", error);
        });
    };
    
    // Call the fetch functions and set up the cleanup logic
    Promise.all([fetchMacrosData()]).then((e) => {
      if (isMounted) {
        // Check if component is still mounted before executing additional logic
        isChecked !== "source"? getIndustryData("Basic Materials") : fetchSourceCountryData("usa");
      }
    });

    // Cleanup function to handle component unmount
    return () => {
      isMounted = false; // Update the flag to indicate that component is unmounted
    };
  }, []);

  const handleDownload = (csvFileDataObj) => {
    csvFileDataObj.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    createCsv(csvFileDataObj, {
      skillKey: { name: industryName, type: "industry" },
    });
  };

  const handleSelectChange = (industry) => {
    if(isChecked == "source"){
      setIndustryName(industry.value);
      fetchSourceCountryData(industry.value)
    }else{
      setIndustryName(industry.value);
      getIndustryData(industry.value);
    }
  };

  const pagination = paginationFactory({
    page: 1,
    showTotal: true,
    sizePerPage: 50,
  });

  return (
    <>
      <Card className="box">
        <Card.Body>
          <Card.Title>
            <div className="skill-data-header-wrapper">
              <h2 className="heading">
                {true && (
                  <div>
                    <div>
                      <span className="text-capitalize">{industryName}</span>
                    </div>
                    <div className="text-muted xs-small">
                      {
                        isChecked === "source" 
                          ? "Country/Source: num_new_jobs_past_x_days" 
                          : isChecked === "industry" 
                            ? <>
                                Industry: Total Job Openings and New Job Openings
                                <br />
                                (28 day moving average)
                              </>
                            : isChecked === "sector" 
                              ? <>
                                  Sector: Total Job Openings and New Job Openings
                                  <br />
                                  (28 day moving average)
                                </>
                              : ""
                      }
                    </div>
                  </div>
                )}
              </h2>
              <div className="macro-data-header">
                <Form className="text-muted xs-small d-flex" id="indutrycheck">
                  <Form.Check
                    type="radio"
                    id="option3"
                    label="Country/Source"
                    value="source"
                    checked={isChecked == "source"}
                    onChange={() => {handleSwitchChange("source")}}
                  />
                  <Form.Check
                    type="radio"
                    id="option1"
                    label="Sector"
                    value="sector"
                    checked={isChecked == "sector"}
                    onChange={() => {handleSwitchChange("sector")}}
                    style={{ marginLeft: "9px" }}
                  />

                  <Form.Check
                    type="radio"
                    id="option2"
                    label="Industry"
                    value="industry"
                    checked={isChecked == "industry"}
                    onChange={() => {handleSwitchChange("industry")}}
                    style={{ marginLeft: "9px" }}
                  />
                </Form>
                <Select
                  className="text-muted xs-small"
                  id="indudstrydropdown"
                  options={industryOrSectorList.map((e) => ({
                    value: e,
                    label: e,
                    key: e,
                  }))}
                  value={{ value: industryName, label: industryName }}
                  onChange={handleSelectChange}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      minWidth: "200px",
                      backgroundColor: "#32323e",
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: "#77778e",
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: "#77778e",
                    }),
                    option: (provided) => ({
                      ...provided,
                      backgroundColor: "#32323e",
                    }),
                  }}
                />
              </div>
            </div>
          </Card.Title>
        </Card.Body>
        {isLoading ? (
          <BounceLoader color={"#36D7B7"} css={override} size={50} />
        ) : (
          <IndustryChart data={finalData} isChecked={isChecked}/>
        )}
      </Card>
      <div style={{ marginTop: 30 }}>
        <Card className="box">
          {
            <h2 className="heading relative">
              {true && <div>Data for {industryName}</div>}
              <Button
                onClick={() => handleDownload(finalData)}
                className={`position-right custom-dark-mode ${
                  !finalData.length ? "disabled" : ""
                }`}
              >
                Download Data
              </Button>{" "}
            </h2>
          }
          {isLoading && (
            <BounceLoader color={"#36D7B7"} css={override} size={50} />
          )}
          {!isLoading && (
            <BootstrapTable
              key="saasTable"
              id="saasTable"
              name="saasTable"
              keyField="date"
              data={finalData}
              columns={columns}
              pagination={pagination}
              sort={{ dataField: "change_date", order: "desc" }}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default IndustryTable;
