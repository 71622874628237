import axios from "axios";

const historicIndustryData = (industry,isChecked) => {  
  const encoded_url = encodeURI(industry).replace("&", "%26");
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url:
      process.env.REACT_APP_SERVER_API + `api/industry?key=${isChecked}&value=${encoded_url}`,
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  });
};
const marcosDataList = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url: process.env.REACT_APP_SERVER_API + `api/marcos-list`,
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  });
};

const countryDataList = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url: process.env.REACT_APP_SERVER_API + `api/country-list`,
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  });
};


const sourceCountryDataList = (country) => {
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url: process.env.REACT_APP_SERVER_API + `api/country-source?source=` + country,
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  });
};

export { historicIndustryData, marcosDataList, sourceCountryDataList, countryDataList };
