import axios from "axios";

export const getCompanyJobStats = (props) => {
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url: process.env.REACT_APP_SERVER_API +"api/datajobstats",
    headers: {
        Authorization: `Token ${accessToken}`,
        "Content-Type": "application/json", 
    },
    params: {
        company_name: props.companyName,
        period_1: props.periodOneQuarter,
        year_1: props.periodOneYear,
        period_2: props.periodTwoQuarter,
        year_2: props.periodTwoYear,
      },
    responseType: "json",
  });
};

export const getCompaniesName = () => {
    const accessToken = window.localStorage.getItem("accessToken");
    return axios({
        method: "get",
        url: process.env.REACT_APP_SERVER_API + "api/companies-list",
        headers: {
            Authorization: `Token ${accessToken}`,
            "Content-Type": "application/json",
          },
        responseType: "json",
      });
}

export const getCompanyNewJobs = (props) => {
    const accessToken = window.localStorage.getItem("accessToken");
    return axios({
        method: "get",
        url: process.env.REACT_APP_SERVER_API + "api/new-jobs/",
        headers: {
            Authorization: `Token ${accessToken}`,
            "Content-Type": "application/json",
          },
        params: {
            company_name: props.companyName,
            period: props.periodOneQuarter,
            year: props.periodOneYear,
        },
        responseType: "json",
      });
}

export const getCompaniesData = (props) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const companyParams = Object.keys(props)
    .filter(key => key.startsWith("company_") && props[key])
    .map(key => `companies=${props[key]}`)
    .join("&");

  const url = `${process.env.REACT_APP_SERVER_API}api/company-job-stats/?period=${props.period}&${companyParams}&year=${props.year}`;

    return axios({
      method: "get",
      url,
      headers: {
          Authorization: `Token ${accessToken}`,
          "Content-Type": "application/json", 
      },
      responseType: "json",
    });
  };