import React from "react";
import { slide as Menu } from "react-burger-menu";
import { NavLink } from 'react-router-dom';
import logoImg from '../../assets/images/logo-white.png';

export default props => {
    const styles = {
        transform: 'translate(10px, 40px)',
        color: 'white',
        fontSize: '20px',
        textDecoration: 'none'
      };
    return (
        <Menu {...props}>
            <div style={{transform: 'translate(0px, -22px)'}}>
                <img src={logoImg} />
            </div>
            <li className="menu-item" >
                <NavLink style={styles}  to="/jobs">JOB TRENDS</NavLink>
            </li>
            <li className="menu-item" >
                <NavLink style={styles}  to="/company">COMPANY TRENDS</NavLink>
            </li>
            {/* <li className="menu-item" >
                <NavLink style={styles}  to="/market-analysis">MARKET ANALYSIS</NavLink>
            </li> */}
            <li className="menu-item" >
                <NavLink style={styles} to="/skills">PRODUCT MENTIONS</NavLink>
            </li>
            <li className="menu-item" >
                <NavLink style={styles} to="/saas">SAAS SUBSCRIPTIONS</NavLink>
            </li>

            <li className="menu-item" >
                <NavLink style={styles} to="/macro">MACRO</NavLink>
            </li>
            <li className="menu-item" >
                <NavLink style={styles} to="/indices-view">INDICES VIEW</NavLink>
            </li>
            <li className="menu-item" >
                <NavLink style={styles} to="/upwork">UPWORK</NavLink>
            </li>
            <li className="menu-item" >
                <NavLink style={styles} to="/installs-downloads">Installs/Downloads</NavLink>
            </li>
            <li className="menu-item" >
                <NavLink style={styles} to="/alerts">ALERTS</NavLink>
            </li>
            <li className="menu-item" >
                <NavLink style={styles} to="/news">NEWS</NavLink>
            </li>
            <li className="menu-item" >
                <NavLink style={styles} to="/docs">DOCS</NavLink>
            </li>
        </Menu>
    );
};
