import {
  getJobTitles,
  getJobLocations,
  getJobCountAndSalary,
} from "../../services/jobMarketAnalysisService";
import MarketAnalysisCharts from "./MarketAnalysisCharts";
import { Col, Card, Row, Button } from "react-bootstrap";
import BounceLoader from "react-spinners/BounceLoader";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { css } from "@emotion/react";
import Select from "react-select";
import "./MarketAnalysis.css";

const override = css`
  display: block;
  margin: 0 auto;
`;

function MarketAnalysis(props) {
  const [loading, setLoading] = useState(true);
  const [jobTitles, setJobTitles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [jobStats, setJobStats] = useState([]);
  const [salaryStats, setSalaryStats] = useState([]);

  const { register, watch, setValue, getValues } = useForm({
    defaultValues: {
      jobTitle: "",
      jobLocation: "",
    },
  });

  const jobTitle = watch("jobTitle");
  const jobLocation = watch("jobLocation");

  const handleTitleChange = (e) => {
    setValue("jobTitle", e?.value);
  };

  const handleLocationChange = (e) => {
    setValue("jobLocation", e?.value);
  };

  const handleSearch = () => {
    getJobStatsByTitle(getValues("jobTitle"));
  };

  const getJobStatsByTitle = (jobTitle) => {
    setLoading(true);
    const params = {
      job_title: jobTitle,
    };

    getJobCountAndSalary(params)
      .then((res) => {
        setJobStats(res?.data?.title_jobs_list);
        setSalaryStats(res?.data?.title_salary_list);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const getJobTitleAndLocation = async () => {
    getJobTitles()
      .then((res) => {
        setJobTitles(res?.data?.job_titles);
        setValue("jobTitle", res?.data?.job_titles[0]);
      })
      .catch((err) => {
        console.log("error", err);
      });

    await getJobLocations()
      .then((res) => {
        setLocations(res?.data?.job_locations);
        setValue("jobLocation", res?.data?.job_locations[0]);
      })
      .catch((err) => {
        console.log("error", err);
      });

    getJobStatsByTitle(getValues("jobTitle"));
  };

  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) {
      window.location.href = "/";
    }

    getJobTitleAndLocation();
  }, []);

  return (
    <div className="h-100">
      <Row>
        <Card className="card-container">
          <Col lg={8}>
            <h4 className="text-white">Job Market Analysis</h4>
          </Col>
          <Col lg={4} className="d-flex align-items-center gap-4 flex-wrap">
            <div className="d-flex align-items-center">
              <h6 className="text-white">Job Title</h6>
              <Select
                className="text-muted xs-small"
                id="indudstrydropdown"
                size="sm"
                placeholder="Enter job title"
                options={jobTitles?.map((e) => ({
                  value: e,
                  label: e,
                  key: e,
                }))}
                value={jobTitle ? { value: jobTitle, label: jobTitle } : null}
                onChange={handleTitleChange}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    minWidth: "200px",
                    backgroundColor: "#32323e",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: "#77778e",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#77778e",
                  }),
                  option: (provided) => ({
                    ...provided,
                    backgroundColor: "#32323e",
                  }),
                }}
              />
            </div>
            {/* <div className="d-flex align-items-center">
              <div className="text-white">Job Location</div>
              <Select
                className="text-muted xs-small"
                id="indudstrydropdown"
                size="sm"
                placeholder="Enter Location"
                options={locations?.map((e) => ({
                  value: e,
                  label: e,
                  key: e,
                }))}
                value={
                  jobLocation
                    ? { value: jobLocation, label: jobLocation }
                    : null
                }
                onChange={handleLocationChange}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    minWidth: "200px",
                    backgroundColor: "#32323e",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: "#77778e",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#77778e",
                  }),
                  option: (provided) => ({
                    ...provided,
                    backgroundColor: "#32323e",
                  }),
                }}
              />
            </div> */}
            <div>
              <Button onClick={handleSearch}>Search</Button>
            </div>
          </Col>
        </Card>
      </Row>
      <Row>
        {loading ? (
          <Card>
            <BounceLoader color={"#36D7B7"} css={override} size={50} />
          </Card>
        ) : (
          <MarketAnalysisCharts jobStats={jobStats} salaryStats={salaryStats} />
        )}
      </Row>
    </div>
  );
}

export default MarketAnalysis;
